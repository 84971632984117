<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-text-field
          ref="searchField"
          v-model="search"
          dense
          outlined
          hide-details
          clearable
          :placeholder="selected.length == 0 ? placeholder : ''"
          @click:clear="clear()"
        >
          <template v-slot:prepend-inner v-if="selected.length">
            <v-container class="pa-0" :style="{ 'max-width': inputMaxWidth }">
              <v-row dense>
                <v-col v-for="item in selected" :key="item[itemValue]">
                  <v-chip
                    color="msaBlue"
                    dark
                    close
                    small
                    @click:close="removeFromSelected(item)"
                  >
                    <span class="text-truncate">
                      {{ item[itemText] }}
                    </span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-text-field>
        <v-card>
          <v-list-item
            v-if="multiple && filteredItems.length > 0"
            dense
            @click="toggleSelectAll"
          >
            <template v-slot:default>
              <v-list-item-action>
                <v-checkbox
                  v-model="selectAllCheckbox"
                  @click.stop
                  :indeterminate="
                    selected.length > 0 && selected.length < items.length
                  "
                  color="msaBlue"
                  @change="toggleSelectAll"
                >
                </v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title> Select all </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-virtual-scroll
            v-if="filteredItems.length"
            :items="filteredItems"
            :height="listHeight"
            :item-height="itemHeight"
          >
            <template v-slot:default="{ item }">
              <v-list-item
                :key="item[itemValue]"
                dense
                @click="selectItem(item)"
              >
                <template v-slot:default>
                  <v-list-item-action v-if="multiple">
                    <v-checkbox v-model="selected" :value="item" @click.stop>
                    </v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-if="!isSearching">
                      {{ item[itemText] }}
                    </v-list-item-title>
                    <v-list-item-title
                      v-else
                      v-html="searchedItemText(item[itemText])"
                    >
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <div
            v-if="isSearching && filteredItems.length == 0"
            class="text-center pa-2"
          >
            No results found
          </div>
          <div
            v-if="!isSearching && filteredItems.length == 0"
            class="text-center pa-2"
          >
            {{ $vuetify.noDataText }}
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AutocompleteWithList',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    numberOfItemsVisible: {
      type: Number,
      default: 5,
    },
    itemHeight: {
      type: Number,
      default: 48,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    itemText: {
      type: String,
      default: 'name',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      search: '',
      listHeight: this.itemHeight * this.numberOfItemsVisible,
      selectAllCheckbox: false,
    };
  },
  computed: {
    filteredItems() {
      if (this.isSearching) {
        return this.items.filter((item) =>
          item[this.itemText]
            .toLowerCase()
            .includes(this.search.trim().toLowerCase()),
        );
      }
      return this.items;
    },
    selectedItemValue() {
      return (item) => (this.returnObject ? item : item[this.itemValue]);
    },
    selectedValues() {
      return (items) =>
        this.returnObject ? items : items.map((item) => item[this.itemValue]);
    },
    isSearching() {
      return this.search && this.search.trim().length > 0;
    },
    // this computed property is used for applying style to result when searching
    searchedItemText() {
      return (text) => {
        const keyword = this.search.trim();
        const startIndex = text.toLowerCase().indexOf(keyword.toLowerCase());
        const firstPart = text.slice(0, startIndex);
        const lastPart = text.slice(startIndex + keyword.length);
        const matchedKeyword = text.slice(
          startIndex,
          startIndex + keyword.length,
        );
        return `<div>${firstPart}<span class="keyword">${matchedKeyword}</span>${lastPart}</div>`;
      };
    },
    inputMaxWidth() {
      if (this.$refs.searchField) {
        return this.$refs.searchField.$el.clientWidth - 64 + 'px';
      }

      return document.body.clientWidth;
    },
  },
  methods: {
    // for click on the item element (not the checkbox element)
    selectItem(item) {
      // emit clicked item immediately if it's single select
      if (!this.multiple) {
        this.$emit('change', this.selectedItemValue(item));
      } else {
        // for multi select, find index then push or splice
        const index = this.selected.findIndex((i) =>
          this.returnObject
            ? i[this.itemValue] == item[this.itemValue]
            : i == item,
        );

        index == -1 ? this.selected.push(item) : this.selected.splice(index, 1);
      }
    },
    clear() {
      this.selected = [];
      this.search = '';
      this.selectAllCheckbox = false;
    },
    removeFromSelected(item) {
      const index = this.selected.findIndex((i) =>
        this.returnObject
          ? i[this.itemValue] == item[this.itemValue]
          : i == item,
      );
      this.selected.splice(index, 1);
    },
    toggleSelectAll() {
      if (
        this.filteredItems.some(
          (item) =>
            !this.selected.some(
              (s) => s[this.itemValue] == item[this.itemValue],
            ),
        )
      ) {
        this.selected = [...this.selected, ...this.filteredItems];
        // remove duplicates
        this.selected = this.selected.filter(
          (item, index, self) =>
            index ===
            self.findIndex((t) => t[this.itemValue] === item[this.itemValue]),
        );
        this.$nextTick(() => {
          this.selectAllCheckbox = true;
        });
      } else {
        this.selected = this.selected.filter(
          (s) =>
            !this.filteredItems.some(
              (item) => item[this.itemValue] == s[this.itemValue],
            ),
        );
        this.$nextTick(() => {
          this.selectAllCheckbox = false;
        });
      }
    },
  },
  watch: {
    selected(items) {
      this.$emit('change', this.selectedValues(items));
      // this is a trick to display the clear button on the search field when there is no search keyword
      if (items.length && !this.isSearching) {
        this.search = ' ';
      }
    },
  },
};
</script>
<style>
.keyword {
  color: rgba(0, 0, 0, 0.38);
  background: #eeeeee;
}
</style>
